import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "when-not-to-use-a-library"
    }}>{`When not to use a library`}</h1>
    <Vimeo id={424606978} mdxType="Vimeo" />
    <p>{`Libraries are great. Quick to get started, quick to keep using, great for
common usecases.`}</p>
    <p>{`Now, if you want to build something custom, something that delights and
astounds, something that makes your users love you ... You're gonna have to
understand how combining React and D3 works under the hood. Whether you use a
library or not.`}</p>
    <p>{`A rule of thumb you can use is to consider how custom you want to make your
visualization. The more customizations, the more likely you are to benefit from
rolling your own.`}</p>
    <p>{`Or if you've had a lot of practice with rolling your own and it's literally
easier than learning a library.`}</p>
    <p>{`If you have to customize an existing library, or build your own for the team,
that's what we're here for today 😃`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      